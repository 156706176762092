import * as React from 'react';

const NotFound: React.FC = () => {

  return (
    <>
      <h1 className="text-center text-6xl">Page Not Found</h1>
      <h2 className="text-center text-3xl text-base text-center">Oops, we couldn&apos;t find this page!</h2>
    </>
  );
};

export default NotFound;
